.header_menu {
  width: 100%;
}

.header_left {
  width: 90%;
  float: left;
  text-align: left;
}

.icon {
  width: 10%;
  // opacity: 100%;
}

.icon_disabled {
  // opacity: 30%;
  width: 10%;
}

.favorite_icon {
  height: 35px;
  cursor: pointer;
  padding: 0 !important
}

.description {
  font-size: large;
}

.comments {
  background-color: red;
  padding: 5px;
  border-radius: 10px;
}

.rows {
  display: inline-block;
}

.row {
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.last_updated{
  color: grey;
  font-size: small;
}
.bold{
  font-weight: bolder;
}
.content{
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
  padding-top: 1%;
}

.sections{
  background-color: red;
}

.teams_section{
  padding-top: 2%;
}